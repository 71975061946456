import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import line from './icons/line.png'
import facebook from './icons/facebook.png'

export function SocialButtons() {
  return (
    <SocialButtonsWrapper>
      <span className="align-items-center d-inline-flex flex-column" style={{ fontSize: 12 }}>
        <CallButton href="tel:062-452-8919" />
        <label>โทรเลย</label>
      </span>

      <span className="align-items-center d-inline-flex flex-column" style={{ fontSize: 12 }}>
        <SocialButton href="https://line.me/ti/p/3s6gzsf40C" title="yotjs">
          <img src={line} />
        </SocialButton>
        <label>คุณยศ</label>
      </span>

      <span className="align-items-center d-inline-flex flex-column" style={{ fontSize: 12 }}>
        <SocialButton href="https://line.me/ti/p/tYIgsHlKEC" title="chuchu11111">
          <img src={line} />
        </SocialButton>
        <label>คุณชุง</label>
      </span>

      <span className="align-items-center d-inline-flex flex-column" style={{ fontSize: 12 }}>
        <SocialButton
          href="https://www.facebook.com/js.stainless.steel.design/"
          title="JS Stainless"
          target="_blank"
        >
          <img src={facebook} />
        </SocialButton>
        <label>แฟนเพจ</label>
      </span>
    </SocialButtonsWrapper>
  )
}
const SocialButtonsWrapper = styled.div`
  display: flex;

  > * + * {
    margin-left: 1.5em;
  }

  > * > label {
    margin-top: 6px;
  }
`
const SocialButton = styled.a`
  > img {
    width: 45px;
    height: 45px;
  }
`
function CallButton(props) {
  return (
    <Circle {...props}>
      <FontAwesomeIcon icon={['fas', 'phone']} flip="horizontal" color="white" />
    </Circle>
  )
}
const Circle = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid white;
`
