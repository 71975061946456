import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import { SocialButtons } from 'components/SocialButtons'

export default function AboutPage() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about-page-feature.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="bg-primary flex-grow-1 pt-ms0">
      <Img
        fluid={data.file.childImageSharp.fluid}
        style={{
          width: '100%',
          maxHeight: 400,
        }}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: '0 25%',
        }}
      />
      <div className="container text-light">
        <section className="d-flex flex-wrap justify-content-center text-center my-ms1 my-md-ms2">
          <article className="flex-grow-1">
            <div className="w-md-50 mx-auto">
              <h1 className="text-white ms1">ประวัติร้าน</h1>
              <p>
                ประกอบกิจการผลิตและจำหน่าย ประตูสแตนเลส โดยช่างผู้ชำนาญงานมากกว่า 30 ปี
                ทั้งงานในรูปแบบ ประตูรั้ว เหล็ก สเตนเลส ราวบันได หลังคา เหล็กดัดมุ้งลวด และอื่นๆ
                ใช้สแตนเลสคุณภาพดี เกรด304 ยินดีให้บริการและให้คำปรึกษาพร้อมการรับประกัน 2 ปีเต็ม
              </p>
            </div>
          </article>
          <div className="mt-ms2">
            <h2 className="mb-2 text-white" style={{ fontSize: 20 }}>
              ติดต่อสอบถาม
            </h2>
            <p className="mb-1">คุณชุง 062-452-8919</p>
            <p>คุณยศ 062-328-8919</p>
            <SocialButtons />
          </div>
        </section>
      </div>
    </div>
  )
}
